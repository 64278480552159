export const socialMediaLinks = {
  instagram: "https://instagram.com/doctorsol.pe?igshid=MzRlODBiNWFlZA==",
  facebook: "https://www.facebook.com/profile.php?id=100092506849635",
  twitter: "#",
  youtube: "https://www.youtube.com/@DoctorSolPeru",
  whatsapp:
    "https://api.whatsapp.com/send/?phone=51956762538&text=Hello%21&type=phone_number&app_absent=0",
  email: "contacto@doctorsol.pe",
  complaintEmail: "ayuda@doctorsol.pe",
  mobileApp:
    "https://play.google.com/store/apps/details?id=com.doctorsol.app.doctorsol_mobile_app&pcampaignid=web_share",
};
